import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Precon from '../../../store/ducks/precon.duck'
import currencyFormat from '../../../components/currencyFormat'
// import NotLoaded, { useIsLoaded } from '../../../components/IsLoaded';
import Contacts from './SubContractorContacts'
import Offices from './SubContractorOffices'
import BidHistory from './SubContractorBidHistory'
import Files from '../../../components/Files'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {NumericFormat} from 'react-number-format'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import InputGroup from 'react-bootstrap/InputGroup'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import EditIcon from '@mui/icons-material/Edit'
import {CustomFormControl, CustomTableCell, CustomTableCellHeader, CustomTableRow} from './styles'
import {numberWithCommas} from '../../../helpers/number'

const Subcontractor = ({subcontractorID, existingSubcontractors, addSubcontractorToBid, removeSubcontractorFromBid, setSelectedContractor, bidTradeQuoteID, closeModal}) => {
  /*  Props:
    subcontractorID
    addSubcontractorToBid - function passed in to add a subcontractor to a BidTrade
    removeSubcontractorFromBid - function passed in to remove a subcontractor from a BidTrade
    closeModal - function passed in to call the parent modal to close
  */

  const subcontractor = useSelector((state) => state.precon.Subcontractor)
  const bidHistory = useSelector((state) => state.precon.SubcontractorBidHistory)
  const contacts = useSelector((state) => state.precon.SubcontractorContacts)
  const primaryContact = contacts.find((row) => row.primaryContact)
  const offices = useSelector((state) => state.precon.SubcontractorOffices)
  const bidTradeQuote = useSelector((state) => state.precon.BidTradeQuote)
  const bidQuoteID = useSelector((state) => state.precon.bidQuoteID)
  const filterBidTradeID = useSelector((state) => state.auth.Filters.bidTradeID)

  const [tabKey, setTabKey] = useState('details')
  const [showEdit, setShowEdit] = useState(subcontractorID === 0 ? true : false)
  const [displayContact, setDisplayContact] = useState({id: 0, fullName: '', phone: ''})
  const [bidderName, setBidderName] = useState('')
  const [editBidderName, setEditBidderName] = useState()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(Precon.actions.fetchSubcontractor({subcontractorID: subcontractorID}))
  }, [dispatch, subcontractorID])
  useEffect(() => {
    dispatch(Precon.actions.fetchSubcontractorContacts({subcontractorID: subcontractorID}))
  }, [dispatch, subcontractorID])
  useEffect(() => {
    dispatch(Precon.actions.fetchSubcontractorBidHistory({subcontractorID: subcontractorID}))
  }, [dispatch, subcontractorID])
  useEffect(() => {
    dispatch(Precon.actions.fetchSubcontractorOffices({subcontractorID: subcontractorID}))
  }, [dispatch, subcontractorID])
  useEffect(() => {
    dispatch(Precon.actions.fetchBidTradeQuote({bidTradeQuoteID: bidTradeQuoteID ? bidTradeQuoteID : 0}))
  }, [dispatch, bidTradeQuoteID])

  // effect to set the displayed contact: either from the default contact for the subcontractor, or the selected contact for a BidTradeQuote
  useEffect(() => {
    let temp = {id: 0, fullName: '', phone: ''}
    if (bidTradeQuote.id > 0) {
      let bidder = contacts.find((row) => row.id === bidTradeQuote.subcontractorContactID)
      setDisplayContact({...temp, ...bidder})
    } else {
      setDisplayContact({...temp, ...primaryContact})
    }
  }, [bidTradeQuote, primaryContact, contacts])

  const quoteStatuses = ['LATE', 'UNDECIDED', 'INVITED', 'NOT_PLANS_AND_SPECS', 'BIDDING', 'NOT_BIDDING', 'BID_SUBMITTED', 'CARRIED', 'INCOMPLETE']

  // const displayContact = {
  //   fullName: '',
  //   phone: '',
  //   ...primaryContact

  //   bidTradeQuote.subcontractorContactID
  // }

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('CompanyName is required')
  })

  const cancelEdit = () => {
    if (subcontractor.id && subcontractor.id > 0) {
      setShowEdit(false)
    } else {
      closeModal()
    }
  }

  const submitForm = (values) => {
    var payload = {
      subcontractor: values
      // tagValuesFilter: tagValuesFilter
    }
    setShowEdit(false)
    dispatch(Precon.actions.putSubcontractor(payload))
  }

  const addToBid = (id) => {
    closeModal()
    addSubcontractorToBid(id)
  }

  const removeFromBid = (id) => {
    closeModal()
    removeSubcontractorFromBid(id)
  }

  const addRemoveSubContractor = () => {
    if (bidTradeQuote.bcbidderID === '' && subcontractorID === subcontractor.id) {
      if (removeSubcontractorFromBid && existingSubcontractors && existingSubcontractors.includes(subcontractorID)) {
        return (
          <Button variant='warning' onClick={() => removeFromBid(subcontractorID)} className='ml-[15px]'>
            Remove from Bid
          </Button>
        )
      }
      if (addSubcontractorToBid) {
        return (
          <Button variant='primary' onClick={() => addToBid(subcontractorID)} className='ml-[15px]'>
            Add to Bid
          </Button>
        )
      }
    } else {
      return <></>
    }
  }

  const selectBid = (bidTradeQuoteID, action) => {
    closeModal()
    setSelectedContractor(bidTradeQuoteID, action)
  }

  const postBidToSummary = (bidTradeQuoteID, action) => {
    let payload = {
      bidTradeQuoteID: bidTradeQuoteID,
      bidQuoteID: bidQuoteID,
      bidTradeID: filterBidTradeID,
      action: action
    }
    dispatch(Precon.actions.putBidTradeQuotePosted(payload))
    closeModal()
  }

  const resetBaseBid = () => {
    let payload = {
      bidTradeQuoteID: bidTradeQuoteID,
      bidQuoteID: bidQuoteID,
      bidTradeID: filterBidTradeID
    }
    dispatch(Precon.actions.putBidTradeQuoteBaseBidReset({bidTradeQuote: payload}))
    dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADEQUOTE'}, clear: true}))
    closeModal()
  }

  const selectSubContractor = () => {
    if (setSelectedContractor) {
      // if we have the function passed in (we are allowed to)
      if (bidTradeQuoteID && bidTradeQuoteID > 0 && bidTradeQuote?.posted) {
        if (bidTradeQuote?.selectedBid) {
          return (
            <Button variant='success' onClick={() => selectBid(bidTradeQuoteID, 'Unselect')} className='ml-[15px]'>
              Unselect as Carried Number
            </Button>
          )
        } else {
          return (
            <Button variant='success' onClick={() => selectBid(bidTradeQuoteID, 'Select')} className='ml-[15px]'>
              Select as Carried Number
            </Button>
          )
        }
      }
    }
    return <></>
  }

  const postSubContractorToSummary = () => {
    // if we have the function passed in (we are allowed to)
    if (bidTradeQuoteID && bidTradeQuoteID > 0) {
      if (bidTradeQuote?.posted) {
        return (
          <Button variant='success' onClick={() => postBidToSummary(bidTradeQuoteID, 'Unpost')} className='ml-[15px]'>
            Remove from Summary
          </Button>
        )
      } else {
        return (
          <Button variant='success' onClick={() => postBidToSummary(bidTradeQuoteID, 'Post')} className='ml-[15px]'>
            Post To Summary
          </Button>
        )
      }
    }
  }

  const changeBidTradeQuoteContact = (id) => {
    console.log(id)
    setDisplayContact(contacts.find((row) => row.id === id))
    dispatch(
      Precon.actions.putBidTradeQuote({
        bidTradeQuote: {...bidTradeQuote, subcontractorContactID: id, bidQuoteID: bidQuoteID}
      })
    )
  }

  const changeBidQuoteStatus = (event) => {
    dispatch(
      Precon.actions.putBidTradeQuote({
        bidTradeQuote: {...bidTradeQuote, status: event.target.value, bidQuoteID: bidQuoteID}
      })
    )
  }

  const startEditBidderName = () => {
    setBidderName(bidTradeQuote.bidderName)
    setEditBidderName(true)
  }

  const changeBidderName = () => {
    dispatch(
      Precon.actions.setPreconCollection({
        collectionName: 'BidTradeQuote',
        grid: {...bidTradeQuote, bidderName: bidderName}
      })
    )
    dispatch(
      Precon.actions.putBidTradeQuote({
        bidTradeQuote: {...bidTradeQuote, bidderName: bidderName, bidQuoteID: bidQuoteID}
      })
    )
    setEditBidderName(false)
    // closeModal()
  }

  let totalClassName = `form-control-status-${bidTradeQuote?.selectedBid ? 'green' : 'blue'}`

  return (
    <div className='container-fluid'>
      <Tabs id='EditModal' activeKey={tabKey} onSelect={(k) => setTabKey(k)} mountOnEnter={true}>
        <Tab eventKey='details' title='Details' className='pt-6 bg-gray-200 p-[6px]'>
          {showEdit ? (
            <Formik
              enableReinitialize={false}
              initialValues={subcontractor}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                submitForm(values)
                // actions.setSubmitting(false);
              }}
            >
              {(props) => {
                const {values, errors, touched, handleChange, handleSubmit} = props
                const change = (name, e) => {
                  handleChange(e)
                }
                return (
                  <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
                    <Row className='mt-8'>
                      <Col md='6'>
                        <Form.Group>
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control
                            type='text'
                            id='companyName'
                            name='companyName'
                            value={values.companyName}
                            onChange={change.bind(null, 'companyName')}
                            autoComplete='new-password'
                            isInvalid={touched.companyName && !!errors.companyName}
                            isValid={touched.companyName && !errors.companyName}
                          />
                          <Form.Control.Feedback type='invalid'>{errors.companyName}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-8'>
                      <Col md='3'>
                        <Form.Group>
                          <Form.Label>Internal Single Limit</Form.Label>
                          <Form.Control
                            type='text'
                            id='internalSingleLimit'
                            name='internalSingleLimit'
                            value={currencyFormat(values.internalSingleLimit, 0)}
                            onChange={change.bind(null, 'internalSingleLimit')}
                            autoComplete='new-password'
                            isInvalid={touched.internalSingleLimit && !!errors.internalSingleLimit}
                            isValid={touched.internalSingleLimit && !errors.internalSingleLimit}
                          />
                          <Form.Control.Feedback type='invalid'>{errors.internalSingleLimit}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md='3'>
                        <Form.Group>
                          <Form.Label>Internal Total Limit</Form.Label>
                          <Form.Control
                            type='text'
                            id='internalTotalLimit'
                            name='internalTotalLimit'
                            value={currencyFormat(values.internalTotalLimit, 0)}
                            onChange={change.bind(null, 'internalTotalLimit')}
                            autoComplete='new-password'
                            isInvalid={touched.internalTotalLimit && !!errors.internalTotalLimit}
                            isValid={touched.internalTotalLimit && !errors.internalTotalLimit}
                          />
                          <Form.Control.Feedback type='invalid'>{errors.internalTotalLimit}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md='3'>
                        <Form.Group>
                          <Form.Label>Largest Project</Form.Label>
                          <Form.Control
                            type='text'
                            id='largestProject'
                            name='largestProject'
                            value={currencyFormat(values.largestProject, 0)}
                            onChange={change.bind(null, 'largestProject')}
                            autoComplete='new-password'
                            isInvalid={touched.largestProject && !!errors.largestProject}
                            isValid={touched.largestProject && !errors.largestProject}
                          />
                          <Form.Control.Feedback type='invalid'>{errors.largestProject}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Grid container justifyContent='space-between'>
                      <Grid item></Grid>
                      <Grid item>
                        <Button variant='secondary' onClick={() => cancelEdit()}>
                          Cancel
                        </Button>
                        <Button variant='primary' type='submit' className='ml-2'>
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )
              }}
            </Formik>
          ) : (
            <>
              <Row className='mb-5'>
                <Col md='12'>
                  <Card>
                    <Card.Body>
                      <Card.Title className='text-[20px]'>
                        <Grid container>
                          <Grid item md={7}>
                            {bidTradeQuote.id ? (
                              <>
                                {editBidderName ? (
                                  <Grid container>
                                    <Grid item md={6}>
                                      <Form.Control type='text' id='status' name='status' value={bidderName} className='form-control-input' onChange={(event) => setBidderName(event.target.value)} />
                                    </Grid>
                                    <Grid item md={6}>
                                      <Button onClick={() => changeBidderName()} className='ml-2'>
                                        Save
                                      </Button>
                                      <Button variant='secondary' onClick={() => setEditBidderName(false)} className='ml-2'>
                                        Cancel
                                      </Button>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <>
                                    {bidTradeQuote.bidderName ? bidTradeQuote.bidderName : subcontractor.companyName}
                                    {bidTradeQuote.bcbidderID === '' ? <EditIcon className='heading-mui-icon' onClick={() => startEditBidderName()}></EditIcon> : null}
                                  </>
                                )}
                              </>
                            ) : (
                              subcontractor.companyName
                            )}
                            {subcontractor.contactedBy === 'System' ? <div className='text-[12px] text-red-primary'>* User created</div> : ''}
                          </Grid>
                          <Grid item md={5}>
                            <Grid container>
                              <Grid item md={6} className='text-right pr-[10px]'>
                                {bidTradeQuote.id ? (
                                  <FormControl>
                                    <Select
                                      name='BidQuoteStatus'
                                      variant='outlined'
                                      size='small'
                                      className='select h-[38px] text-left'
                                      value={bidTradeQuote.status || ''}
                                      onChange={(event) => changeBidQuoteStatus(event)}
                                    >
                                      {quoteStatuses.map((row, index) => (
                                        <MenuItem key={index} className='text-left' value={row} label={row}>
                                          {row}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                ) : null}
                              </Grid>
                              <Grid item md={6}>
                                {bidTradeQuote.id ? (
                                  <InputGroup className={`${totalClassName} h-[38px]`}>
                                    <InputGroup.Text id='basic-addon1' className={`${totalClassName} h-[38px]`}>
                                      $
                                    </InputGroup.Text>
                                    <Form.Control
                                      type='text'
                                      id='status'
                                      name='status'
                                      value={`${numberWithCommas(bidTradeQuote.total)}${bidTradeQuote.ratioToCheapest > 0 ? ` (+${bidTradeQuote.ratioToCheapest}%)` : ''}`}
                                      className={`${totalClassName} text-right h-[38px]`}
                                      disabled
                                    />
                                  </InputGroup>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card.Title>
                      <Card.Subtitle className='mb-2 text-muted'>
                        <Grid container className='w-[350px]'>
                          <Grid item md={2}>
                            <AccountBoxIcon className='text-[50px] text-purple-200 mt-[2px]' />
                          </Grid>
                          <Grid item md={10} className='pt-[6px]'>
                            <CustomFormControl>
                              <Select value={displayContact.id > 0 ? displayContact.id : ''} onChange={(event) => changeBidTradeQuoteContact(event.target.value)}>
                                {contacts.map((row, index) => {
                                  return (
                                    <MenuItem key={index} value={row.id} className='subcontractor-contact-menuitem block'>
                                      <div className='subcontractor-contant-name'>{row.fullName}</div>
                                      <div className='subcontractor-contant-phone'>{row.phone}</div>
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </CustomFormControl>
                          </Grid>
                        </Grid>
                      </Card.Subtitle>

                      <Row>
                        <Col md='3'>
                          <ListGroup variant='flush'>
                            <ListGroup.Item onClick={() => setTabKey('bidhistory')} className='card-list-link'>
                              <Grid container justifyContent='space-between'>
                                <Grid item>{bidHistory.length > 0 ? `Won ${bidHistory.filter((bid) => bid.selectedBid).length} out of ${bidHistory.length}` : '0'} bids</Grid>
                                <Grid item>
                                  <ChevronRightIcon />
                                </Grid>
                              </Grid>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={() => setTabKey('contacts')} className='card-list-link'>
                              <Grid container justifyContent='space-between'>
                                <Grid item>
                                  {contacts.length} Contact{contacts.length === 1 ? '' : 's'}
                                </Grid>
                                <Grid item>
                                  <ChevronRightIcon />
                                </Grid>
                              </Grid>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={() => setTabKey('offices')} className='card-list-link'>
                              <Grid container justifyContent='space-between'>
                                <Grid item>
                                  {offices.length} Office{offices.length === 1 ? '' : 's'}
                                </Grid>
                                <Grid item>
                                  <ChevronRightIcon />
                                </Grid>
                              </Grid>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={() => setTabKey('trades')} className='card-list-link'>
                              <Grid container justifyContent='space-between'>
                                <Grid item>
                                  {subcontractor.trades.length} Trade
                                  {subcontractor.trades.length === 1 ? '' : 's'}
                                </Grid>
                                <Grid item>
                                  <ChevronRightIcon />
                                </Grid>
                              </Grid>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={() => setTabKey('trades')} className='card-list-link'>
                              <Grid container justifyContent='space-between'>
                                <Grid item>
                                  {subcontractor.regions.length} Region
                                  {subcontractor.regions.length === 1 ? '' : 's'}
                                </Grid>
                                <Grid item>
                                  <ChevronRightIcon />
                                </Grid>
                              </Grid>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={() => setTabKey('trades')} className='card-list-link'>
                              <Grid container justifyContent='space-between'>
                                <Grid item>
                                  {subcontractor.markets.length} Market
                                  {subcontractor.markets.length === 1 ? '' : 's'}
                                </Grid>
                                <Grid item>
                                  <ChevronRightIcon />
                                </Grid>
                              </Grid>
                            </ListGroup.Item>
                          </ListGroup>
                          <Button onClick={() => setShowEdit(true)} size='sm'>
                            Edit
                          </Button>
                        </Col>
                        <Col md='4'>
                          <ListGroup variant='flush'>
                            <ListGroup.Item className='card-list-item'>
                              <Grid container>
                                <Grid item md={6} className='card-list-item-label'>
                                  Single Limit
                                </Grid>
                                <Grid item md={6} className='card-list-item-value'>
                                  <NumericFormat displayType='text' value={subcontractor.internalSingleLimit} decimalScale={0} thousandSeparator />
                                </Grid>
                              </Grid>
                            </ListGroup.Item>
                            <ListGroup.Item className='card-list-item'>
                              <Grid container>
                                <Grid item md={6} className='card-list-item-label'>
                                  Total Limit
                                </Grid>
                                <Grid item md={6} className='card-list-item-value'>
                                  <NumericFormat displayType='text' value={subcontractor.internalTotalLimit} decimalScale={0} thousandSeparator />
                                </Grid>
                              </Grid>
                            </ListGroup.Item>
                            <ListGroup.Item className='card-list-item'>
                              <Grid container>
                                <Grid item md={6} className='card-list-item-label'>
                                  Current Backlog
                                </Grid>
                                <Grid item md={6} className='card-list-item-value'>
                                  <NumericFormat displayType='text' value={subcontractor.currentEstimatedBacklog} decimalScale={0} thousandSeparator />
                                </Grid>
                              </Grid>
                            </ListGroup.Item>
                            <ListGroup.Item className='card-list-item'>
                              <Grid container>
                                <Grid item md={6} className='card-list-item-label'>
                                  {`${subcontractor.lastRevenueYear} `}Revenue
                                </Grid>
                                <Grid item md={6} className='card-list-item-value'>
                                  <NumericFormat displayType='text' value={subcontractor.lastRevenueVolume} decimalScale={0} thousandSeparator />
                                </Grid>
                              </Grid>
                            </ListGroup.Item>
                          </ListGroup>
                        </Col>
                        <Col md='5'>
                          {bidTradeQuote.id ? (
                            <ListGroup variant='flush'>
                              <ListGroup.Item className='card-list-item'>
                                <Grid container>
                                  <Grid item md={6} className='card-list-item-label'>
                                    BC Proposal Base Bid
                                  </Grid>
                                  <Grid item md={6} className='card-list-item-value'>
                                    <NumericFormat displayType='text' value={bidTradeQuote.baseBidByBC} decimalScale={0} thousandSeparator />
                                  </Grid>
                                </Grid>
                              </ListGroup.Item>
                              {bidTradeQuote.baseBidSource === 'User' ? (
                                <>
                                  <ListGroup.Item className='card-list-item'>
                                    <Grid container>
                                      <Grid item md={6} className='card-list-item-label'>
                                        User Updated Base Bid
                                      </Grid>
                                      <Grid item md={6} className='card-list-item-value'>
                                        <NumericFormat displayType='text' value={bidTradeQuote.baseBidByUser} decimalScale={0} thousandSeparator />
                                      </Grid>
                                    </Grid>
                                  </ListGroup.Item>
                                  <ListGroup.Item className='card-list-item'>
                                    <Grid container>
                                      <Grid item md={6} className='card-list-item-label'></Grid>
                                      <Grid item md={6} className='card-list-item-value'>
                                        {bidTradeQuote.baseBidByBC !== bidTradeQuote.baseBidByUser ? <Button onClick={() => resetBaseBid()}>Reset Base Bid</Button> : null}
                                      </Grid>
                                    </Grid>
                                  </ListGroup.Item>
                                </>
                              ) : null}
                            </ListGroup>
                          ) : null}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Grid container justifyContent='space-between'>
                <Grid item></Grid>
                <Grid item>
                  <Button variant='secondary' onClick={() => closeModal()}>
                    Close
                  </Button>
                  {addRemoveSubContractor()}
                  {postSubContractorToSummary()}
                  {selectSubContractor()}
                </Grid>
              </Grid>
            </>
          )}
        </Tab>

        {subcontractor.id ? (
          <Tab eventKey='bidhistory' title='Bid History' className='pt-6 px-2'>
            <BidHistory subcontractorID={subcontractor.id} />
          </Tab>
        ) : null}

        {subcontractor.id && bidTradeQuote && bidTradeQuote.id ? (
          <Tab eventKey='bidfiles' title='Bid Files' className='pt-6 px-2'>
            <Files bidTradeQuoteID={bidTradeQuote.id} bidTradeID={bidTradeQuote.bidTradeID} />
          </Tab>
        ) : null}

        {subcontractor.id ? (
          <Tab eventKey='contacts' title='Contacts' className='pt-6 px-2'>
            <Contacts subcontractorID={subcontractor.id} />
          </Tab>
        ) : null}

        {subcontractor.id ? (
          <Tab eventKey='offices' title='Offices' className='pt-6 px-2'>
            <Offices subcontractorID={subcontractor.id} />
          </Tab>
        ) : null}

        {/* {subcontractor.id ?
				<Tab eventKey="tags" title="Tags" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6, height: 700 }}>				
					<Row>
					  <Col md="8">
              <Tags args={{ 
                context: 'Subcontractor',
                fkID: subcontractor.id, 
                autoSave: true,
                display: { layout: 'form', header: false, box: true },
                readOnly: false
              }}/>
					  </Col>
					</Row>
				</Tab>
        : null}	 */}

        {subcontractor.id ? (
          <Tab eventKey='trades' title='Regions, Markets and Trades' className='pt-6 px-2'>
            <Row>
              <Col md='3'>
                <TableContainer component={Paper}>
                  <Table aria-label='Regions'>
                    <TableHead>
                      <CustomTableRow>
                        <CustomTableCellHeader>Regions</CustomTableCellHeader>
                      </CustomTableRow>
                    </TableHead>
                    <TableBody>
                      {subcontractor.regions
                        ? subcontractor.regions.map((row) => (
                            <CustomTableRow key={row}>
                              <CustomTableCell>{row}</CustomTableCell>
                            </CustomTableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
              <Col md='3'>
                <TableContainer component={Paper}>
                  <Table aria-label='Markets'>
                    <TableHead>
                      <CustomTableRow>
                        <CustomTableCellHeader>Markets</CustomTableCellHeader>
                      </CustomTableRow>
                    </TableHead>
                    <TableBody>
                      {subcontractor.markets
                        ? subcontractor.markets.map((row) => (
                            <CustomTableRow key={row}>
                              <CustomTableCell>{row}</CustomTableCell>
                            </CustomTableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
              <Col md='6'>
                <TableContainer component={Paper}>
                  <Table aria-label='Trades'>
                    <TableHead>
                      <CustomTableRow>
                        <CustomTableCellHeader>Code</CustomTableCellHeader>
                        <CustomTableCellHeader>Trade</CustomTableCellHeader>
                      </CustomTableRow>
                    </TableHead>
                    <TableBody>
                      {subcontractor.trades
                        ? subcontractor.trades.map((row, index) => (
                            <CustomTableRow key={index}>
                              <CustomTableCell component='th' scope='row'>
                                {row.tradeCode}
                              </CustomTableCell>
                              <CustomTableCell>{row.trade}</CustomTableCell>
                            </CustomTableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
            </Row>
          </Tab>
        ) : null}
      </Tabs>
    </div>
  )
}

export default Subcontractor
